var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("multi-select", {
        attrs: {
          search: "",
          "history-button": "",
          options: _vm.options,
          "select-options": _vm.data,
          "btn-label": _vm.btnLabel
        },
        model: {
          value: _vm.values,
          callback: function($$v) {
            _vm.values = $$v
          },
          expression: "values"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }