var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cesium" }, [
    _vm.showUI
      ? _c("div", { attrs: { id: "toolbarLeft" } }, [
          _c("div", { staticClass: "toolbarButtons" }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.cat,
                  expression: "menu.cat"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Tracked satellite\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "toolbarContent" },
                [_c("satellite-select", { ref: "SatelliteSelect" })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Enabled satellites\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "toolbarContent" },
                [_c("satellite-multi-select", { ref: "SatelliteMultiSelect" })],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Monitored satellites\n      ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "toolbarContent" },
                [
                  _c("satellite-notify-multi-select", {
                    ref: "SatelliteNotifyMultiSelect"
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.sat,
                  expression: "menu.sat"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Satellite elements\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.cc.sats.components, function(componentName) {
                return _c(
                  "label",
                  { key: componentName, staticClass: "toolbarSwitch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.enabledComponents,
                          expression: "enabledComponents"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: componentName,
                        checked: Array.isArray(_vm.enabledComponents)
                          ? _vm._i(_vm.enabledComponents, componentName) > -1
                          : _vm.enabledComponents
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.enabledComponents,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = componentName,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.enabledComponents = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.enabledComponents = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.enabledComponents = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "slider" }),
                    _vm._v("\n        " + _vm._s(componentName) + "\n      ")
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.gs,
                  expression: "menu.gs"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Ground station\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.groundStationPicker.enabled,
                      expression: "groundStationPicker.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.groundStationPicker.enabled)
                      ? _vm._i(_vm.groundStationPicker.enabled, null) > -1
                      : _vm.groundStationPicker.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.groundStationPicker.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.groundStationPicker,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.groundStationPicker,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.groundStationPicker, "enabled", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        Pick on globe\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.cc.setGroundStationFromGeolocation()
                    }
                  }
                }),
                _vm._v("\n        Set from geolocation\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.cc.sats.focusGroundStation()
                    }
                  }
                }),
                _vm._v("\n        Focus\n      ")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.map,
                  expression: "menu.map"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Layers\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.cc.imageryProviders, function(name) {
                return _c(
                  "label",
                  { key: name, staticClass: "toolbarSwitch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.imageryProvider,
                          expression: "imageryProvider"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: name,
                        checked: _vm._q(_vm.imageryProvider, name)
                      },
                      on: {
                        change: function($event) {
                          _vm.imageryProvider = name
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "slider" }),
                    _vm._v("\n        " + _vm._s(name) + "\n      ")
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Terrain\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.cc.terrainProviders, function(name) {
                return _c(
                  "label",
                  { key: name, staticClass: "toolbarSwitch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.terrainProvider,
                          expression: "terrainProvider"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: name,
                        checked: _vm._q(_vm.terrainProvider, name)
                      },
                      on: {
                        change: function($event) {
                          _vm.terrainProvider = name
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "slider" }),
                    _vm._v("\n        " + _vm._s(name) + "\n      ")
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        View\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.cc.sceneModes, function(name) {
                return _c(
                  "label",
                  { key: name, staticClass: "toolbarSwitch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.sceneMode,
                          expression: "sceneMode"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: name,
                        checked: _vm._q(_vm.sceneMode, name)
                      },
                      on: {
                        change: function($event) {
                          _vm.sceneMode = name
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "slider" }),
                    _vm._v("\n        " + _vm._s(name) + "\n      ")
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Camera\n      ")
              ]),
              _vm._v(" "),
              _vm._l(_vm.cc.cameraModes, function(name) {
                return _c(
                  "label",
                  { key: name, staticClass: "toolbarSwitch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cameraMode,
                          expression: "cameraMode"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: name,
                        checked: _vm._q(_vm.cameraMode, name)
                      },
                      on: {
                        change: function($event) {
                          _vm.cameraMode = name
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "slider" }),
                    _vm._v("\n        " + _vm._s(name) + "\n      ")
                  ]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.ios,
                  expression: "menu.ios"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Mobile\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.useWebVR,
                      expression: "cc.viewer.scene.useWebVR"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.cc.viewer.scene.useWebVR)
                      ? _vm._i(_vm.cc.viewer.scene.useWebVR, null) > -1
                      : _vm.cc.viewer.scene.useWebVR
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.useWebVR,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "useWebVR",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "useWebVR",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cc.viewer.scene, "useWebVR", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        VR\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.clock.shouldAnimate,
                      expression: "cc.viewer.clock.shouldAnimate"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.cc.viewer.clock.shouldAnimate)
                      ? _vm._i(_vm.cc.viewer.clock.shouldAnimate, null) > -1
                      : _vm.cc.viewer.clock.shouldAnimate
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.clock.shouldAnimate,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.clock,
                              "shouldAnimate",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.clock,
                              "shouldAnimate",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cc.viewer.clock, "shouldAnimate", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        Play\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.cc.viewer.clockViewModel.multiplier *= 2
                    }
                  }
                }),
                _vm._v("\n        Increase play speed\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.cc.viewer.clockViewModel.multiplier /= 2
                    }
                  }
                }),
                _vm._v("\n        Decrease play speed\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go({ path: "", force: true })
                    }
                  }
                }),
                _vm._v("\n        Reload\n      ")
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menu.dbg,
                  expression: "menu.dbg"
                }
              ],
              staticClass: "toolbarSwitches"
            },
            [
              _c("div", { staticClass: "toolbarTitle" }, [
                _vm._v("\n        Debug\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.debugShowFramesPerSecond,
                      expression: "cc.viewer.scene.debugShowFramesPerSecond"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.cc.viewer.scene.debugShowFramesPerSecond
                    )
                      ? _vm._i(
                          _vm.cc.viewer.scene.debugShowFramesPerSecond,
                          null
                        ) > -1
                      : _vm.cc.viewer.scene.debugShowFramesPerSecond
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.debugShowFramesPerSecond,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "debugShowFramesPerSecond",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "debugShowFramesPerSecond",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.cc.viewer.scene,
                          "debugShowFramesPerSecond",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        FPS\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.requestRenderMode,
                      expression: "cc.viewer.scene.requestRenderMode"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.cc.viewer.scene.requestRenderMode
                    )
                      ? _vm._i(_vm.cc.viewer.scene.requestRenderMode, null) > -1
                      : _vm.cc.viewer.scene.requestRenderMode
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.requestRenderMode,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "requestRenderMode",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "requestRenderMode",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cc.viewer.scene, "requestRenderMode", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        RequestRender\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.fog.enabled,
                      expression: "cc.viewer.scene.fog.enabled"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.cc.viewer.scene.fog.enabled)
                      ? _vm._i(_vm.cc.viewer.scene.fog.enabled, null) > -1
                      : _vm.cc.viewer.scene.fog.enabled
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.fog.enabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.fog,
                              "enabled",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.fog,
                              "enabled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cc.viewer.scene.fog, "enabled", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        Fog\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.globe.enableLighting,
                      expression: "cc.viewer.scene.globe.enableLighting"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.cc.viewer.scene.globe.enableLighting
                    )
                      ? _vm._i(_vm.cc.viewer.scene.globe.enableLighting, null) >
                        -1
                      : _vm.cc.viewer.scene.globe.enableLighting
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.globe.enableLighting,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.globe,
                              "enableLighting",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.globe,
                              "enableLighting",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.cc.viewer.scene.globe,
                          "enableLighting",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        Lighting\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.highDynamicRange,
                      expression: "cc.viewer.scene.highDynamicRange"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.cc.viewer.scene.highDynamicRange)
                      ? _vm._i(_vm.cc.viewer.scene.highDynamicRange, null) > -1
                      : _vm.cc.viewer.scene.highDynamicRange
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.highDynamicRange,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "highDynamicRange",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene,
                              "highDynamicRange",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.cc.viewer.scene, "highDynamicRange", $$c)
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        HDR\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cc.viewer.scene.globe.showGroundAtmosphere,
                      expression: "cc.viewer.scene.globe.showGroundAtmosphere"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(
                      _vm.cc.viewer.scene.globe.showGroundAtmosphere
                    )
                      ? _vm._i(
                          _vm.cc.viewer.scene.globe.showGroundAtmosphere,
                          null
                        ) > -1
                      : _vm.cc.viewer.scene.globe.showGroundAtmosphere
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.cc.viewer.scene.globe.showGroundAtmosphere,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.globe,
                              "showGroundAtmosphere",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.cc.viewer.scene.globe,
                              "showGroundAtmosphere",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(
                          _vm.cc.viewer.scene.globe,
                          "showGroundAtmosphere",
                          $$c
                        )
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "slider" }),
                _vm._v("\n        Atmosphere\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.cc.jumpTo("Everest")
                    }
                  }
                }),
                _vm._v("\n        Jump to Everest\n      ")
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "toolbarSwitch" }, [
                _c("input", {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.cc.jumpTo("HalfDome")
                    }
                  }
                }),
                _vm._v("\n        Jump to HalfDome\n      ")
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { id: "toolbarRight" } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }